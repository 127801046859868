import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTable } from 'react-table';
import { useTranslation } from 'react-i18next';
import { useCookies } from 'react-cookie';
import ReactTooltip from 'react-tooltip';
import InfiniteScroll from 'react-infinite-scroll-component';

import {
    getReports,
    reportsFetchingState,
    reportsFilterState,
    reportsState,
} from '../../../../../redux/slices/newsAndPollsSlice';
import {
    auth_key_calendar as authKeyCalendarState,
    username as usernameState,
} from '../../../../../redux/slices/loginSlice';
import { url } from '../../../../../api/config';

import { SvgComponent } from '../../../../common/svgComponent/SvgComponent';
import { Loader } from '../../../../common/Loader';

export const ReportsSection = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [cookies] = useCookies();

    const loading = useSelector(reportsFetchingState);
    const reports = useSelector(reportsState);
    const filter = useSelector(reportsFilterState);
    const username = useSelector(usernameState);
    const authKeyCalendar = useSelector(authKeyCalendarState);

    const lang = useMemo(() => cookies.i18next, [cookies]);

    const columns = useMemo(
        () => [
            {
                Header: t('NEWS_AND_POLLS_TITLE'),
                accessor: 'title',
            },
            {
                Header: t('NEWS_AND_POLLS_OBJECTS'),
                accessor: 'objects',
            },
            {
                Header: t('NEWS_AND_POLLS_COMPLETED'),
                accessor: 'stat',
            },
            {
                Header: t('NEWS_AND_POLLS_TYPE'),
                accessor: 'type',
            },
            {
                Header: t('NEWS_AND_POLLS_DATE_START'),
                accessor: 'dateStart',
            },
            {
                Header: t('NEWS_AND_POLLS_DATE_END'),
                accessor: 'dateEnd',
            },
            {
                Header: t('NEWS_AND_POLLS_DOWNLOAD'),
                accessor: 'download',
            },
        ],
        [lang]
    );

    const data = useMemo(() => reports.items.map(item => ({ ...item, type: t(item.type) })), [reports, lang]);

    const mainElement = useMemo(() => document.querySelector('#wrapper'), [reports]);

    useEffect(() => {
        dispatch(getReports(filter));
    }, [filter]);

    useEffect(() => {
        const hasScroll = mainElement ? mainElement.scrollHeight > mainElement.clientHeight : false;
        const hasMore = reports.items.length < reports.totalCount;

        if (hasMore && !hasScroll && !loading) {
            setTimeout(() => {
                dispatch(getReports({ ...filter, page: (reports.items.length / 10 + 1).toString() }));
            }, 200);
        }
    }, [mainElement, reports, loading]);

    useEffect(() => {
        ReactTooltip.rebuild();

        console.log('data', data);
    }, [data]);

    const { getTableProps, getTableBodyProps, rows, prepareRow, headerGroups } = useTable({ columns, data });

    return (
        <div className="flex flex-col gap-6 p-6">
            {loading ? (
                <div className="flex flex-1 justify-center items-center">
                    <Loader width={24} height={24} />
                </div>
            ) : data.length ? (
                <InfiniteScroll
                    next={() => {
                        dispatch(getReports({ ...filter, page: (rows.length / 10 + 1).toString() }));
                    }}
                    loader={
                        <div className="flex justify-center mt-2">
                            <Loader width={24} height={24} />
                        </div>
                    }
                    dataLength={rows.length}
                    hasMore={rows.length < reports.totalCount}
                    scrollableTarget="wrapper"
                >
                    <div className="w-full h-full md:border md:border-gray-10 md:overflow-hidden md:rounded-lg">
                        <table
                            {...getTableProps()}
                            className="w-full border-collapse border-spacing-0 text-xs text-gray-500 font-medium"
                        >
                            <thead className="uppercase text-black text-left hidden md:table-header-group">
                                {headerGroups.map(headerGroup => (
                                    <tr {...headerGroup.getHeaderGroupProps()} className="border-b border-b-gray-10">
                                        {headerGroup.headers.map(column => {
                                            return (
                                                <th
                                                    {...column.getHeaderProps()}
                                                    className="bg-white font-medium px-2 first:pl-4 last:pr-4 py-5"
                                                >
                                                    {column.render('Header')}
                                                </th>
                                            );
                                        })}
                                    </tr>
                                ))}
                            </thead>

                            <tbody {...getTableBodyProps()}>
                                {rows.map(row => {
                                    prepareRow(row);

                                    return (
                                        <tr
                                            {...row.getRowProps()}
                                            className="block md:table-row mb-4 md:mb-0 border md:border-0 md:border-b md:last:border-b-0 border-gray-10 rounded-lg overflow-hidden bg-white"
                                        >
                                            {row.cells.map(cell => {
                                                let tdJSX = (
                                                    <div className="inline-block text-gray-500 text-sm md:line-clamp-2">
                                                        {cell.render('Cell')}
                                                    </div>
                                                );

                                                if (cell.column.id === 'title') {
                                                    tdJSX = (
                                                        <div
                                                            className="text-gray-500 text-sm md:line-clamp-2"
                                                            data-tip={cell.value}
                                                        >
                                                            {cell.render('Cell')}
                                                        </div>
                                                    );
                                                }

                                                if (cell.column.id === 'objects') {
                                                    tdJSX = (
                                                        <div className="inline-block text-gray-500 text-sm">
                                                            {cell.value.length || '-'}
                                                        </div>
                                                    );
                                                }

                                                if (cell.column.id === 'stat') {
                                                    tdJSX = (
                                                        <div className="inline-block text-gray-500 text-sm">
                                                            {cell.value}
                                                        </div>
                                                    );
                                                }

                                                if (cell.column.id === 'download') {
                                                    tdJSX = (
                                                        <Link
                                                            to={`${url}/api/v1/poll/export-excel?id=${cell.value}&auth_key_calendar=${authKeyCalendar}&username=${username}`}
                                                            className="text-blue text-sm flex flex-row gap-1.5 items-center hover:underline"
                                                        >
                                                            <SvgComponent name="download" />
                                                            {t('NEWS_AND_POLLS_DOWNLOAD')}
                                                        </Link>
                                                    );
                                                }

                                                return (
                                                    <td
                                                        {...cell.getCellProps()}
                                                        data-label={cell.column.Header}
                                                        className={`flex justify-between items-center gap-4 md:table-cell text-right md:text-left before:content-[attr(data-label)] before:uppercase md:before:hidden py-4 px-3 md:first:pl-4 md:last:pr-4 border-b last:border-b-0 md:border-b-0 border-gray-10 ${cell.column.id === 'title' && 'md:w-[20%]'}`}
                                                    >
                                                        {tdJSX}
                                                    </td>
                                                );
                                            })}
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </InfiniteScroll>
            ) : (
                <div>Ничего не найдено</div>
            )}
        </div>
    );
};
